import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Meta from 'vue-meta'

import store from './store/index'
import './plugins/veevalidate'

import FileUpload from "@/components/FileUpload";

import {getFirestore, collection, getDocs} from 'firebase/firestore'


Vue.config.productionTip = true

Vue.component('file-upload', FileUpload);

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyA55O7vmACsd8YW5P7Sfv8ynJ3LRe1_KC8",
    authDomain: "scan-and-go-b86bc.firebaseapp.com",
    projectId: "scan-and-go-b86bc",
    storageBucket: "scan-and-go-b86bc.appspot.com",
    messagingSenderId: "471904669800",
    appId: "1:471904669800:web:f9fc27979c853d3fa42bdd",
    measurementId: "G-1DY137C5WJ"
};

// Initialize Firebase
initializeApp(firebaseConfig);
export let db = getFirestore();

import Paginate from 'vuejs-paginate'
Vue.component('paginate', Paginate)


import API from '/src/api'

Vue.config.productionTip = false

Vue.prototype.$api = API;
Vue.use(Meta)

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
